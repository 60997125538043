import React, { Component } from "react";
import logo from "../assests/nav-bar-logo.jpg"

export default class Navbar extends Component {
  render() {
    return (
      <div>
        <nav
          className="navbar navbar-expand-lg navbar-light "
          style={{ backgroundColor: "rgb(3,47,60)" }}
        >
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img
                src={logo}
                alt="Company Logo"
                style={{ width: "130px", height: "45px" }}
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link active text-white" aria-current="page" href="#home" >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="#about">
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="#services">
                    Services
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="#contactus">
                    Contact Us
                  </a>
                </li>
                
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
