// src/components/ContactUs.js
import React from "react";
import "../styles/ContactUs.css"; // Import the CSS file for styling

export default function ContactUs() {
  return (
    <div id="contactus">
      <footer className="footer-section bg-dark text-light py-5">
        <div className="container">
          {/* Locations Row */}
          <div className="row text-light">
            {/* Branch 1 */}
            <div className="col-md-4 mb-3 d-flex flex-column">
              <strong>Head Office:</strong>
              <p>
                Ground Floor, D-31, Shop No-3, Budh Vihar, Badarpur, New Delhi,
                110044
              </p>
            </div>
            {/* Branch 2 */}
            <div className="col-md-4 mb-3 d-flex flex-column">
              <strong>Branch 1:</strong>
              <p>
                P-384, Sector-40, Gurugram, Haryana, Gurgaon Sector 45, Sector
                -45, Gurgaon- 122003, Haryana
              </p>
            </div>
            {/* Branch 3 */}
            <div className="col-md-4 mb-3 d-flex flex-column">
              <strong>Branch 2:</strong>
              <p>
                Focal Point, Sultanwind road, Near Guru Harkrishan School
                Amritsar
              </p>
            </div>
            {/* Branch 3 */}
            <div className="col-md-4 mb-3 d-flex flex-column">
              <strong>Branch 3:</strong>
              <p>Sunita Apartment, Sector 26, Vashi, New Mumbai</p>
            </div>

            {/* Branch 4 */}
            <div className="col-md-4 mb-3 d-flex flex-column">
              <strong>Branch 4:</strong>
              <p>
                Plot No. 955/2034, At Patna, Balianta, P.O. - Balianta, Khordha,
                Pin-752101
              </p>
            </div>
          </div>

          {/* Contact and Social Media Row */}
          <div className="row mt-4">
            {/* Contact Details */}
            <div className="col-md-6">
              <h4 className="mb-4">Contact Details</h4>
              <ul className="list-unstyled">
                <li>
                  <strong>Phone:</strong> +91-8862862002
                </li>
                <li>
                  <strong>Email:</strong> shailruchifacility@gamil.com
                </li>
              </ul>
            </div>

            {/* Social Media Links */}
            {/* <div className="col-md-6">
              <h4 className="mb-4">Connect With Us</h4>
              <div className="social-links">
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <i className="fab fa-facebook-f"></i> Facebook
                </a>
                <a
                  href="https://www.twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <i className="fab fa-twitter"></i> Twitter
                </a>
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <i className="fab fa-linkedin-in"></i> LinkedIn
                </a>
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <i className="fab fa-instagram"></i> Instagram
                </a>
                <a
                  href="https://www.youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-icon"
                >
                  <i className="fab fa-youtube"></i> YouTube
                </a>
              </div>
            </div> */}
          </div>

          {/* Footer Bottom Section */}
          <div className="footer-bottom text-center mt-4 pt-4 border-top">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} Shailruchi Facility Care Private
              Limited. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
