import React, { Component } from "react";

import aboutus from "../assests/aboutus.jpg";

export default class About extends Component {
  render() {
    return (
      <div id="about">
        <section className="about-section py-5">
          <div className="container">
            {/* Section Heading */}
            <div className="text-center mb-5">
              <h1 className="fw-bold">About Us</h1>
              <p className="text-muted">
                Discover our story, our aspirations, and the innovative services
                we bring to the table.
              </p>
            </div>

            {/* About Section */}
            <div className="row align-items-center mb-5">
              <div className="col-lg-6">
                <img
                  src={aboutus}
                  alt="About Us"
                  className="img-fluid rounded shadow-lg"
                />
              </div>

              <div className="col-lg-6">
                <h2 className="fw-bold">Our Vision</h2>
                <p className="lead">
                  As a dynamic startup, our vision is to revolutionize the
                  industry with creativity and agility.
                </p>
                <p>
                  We are driven by a passion for innovation and a commitment to
                  delivering exceptional value to our clients. Every step we
                  take is aimed at pushing boundaries and creating meaningful
                  solutions.
                </p>
                <a href="#services" className="btn btn-primary mt-3">
                  Discover Our Services
                </a>
              </div>
            </div>

            {/* Milestones Section */}
            {/* <div className="row text-center py-4 bg-light rounded mb-5">
              <div className="col-md-3">
                <h2 className="text-primary fw-bold">1+</h2>
                <p className="text-muted">Years of Journey</p>
              </div>
              <div className="col-md-3">
                <h2 className="text-primary fw-bold">100+</h2>
                <p className="text-muted">Satisfied Clients</p>
              </div>
              <div className="col-md-3">
                <h2 className="text-primary fw-bold">150+</h2>
                <p className="text-muted">Projects Delivered</p>
              </div>
              <div className="col-md-3">
                <h2 className="text-primary fw-bold">5+</h2>
                <p className="text-muted">Industry Recognitions</p>
              </div>
            </div> */}

            {/* Customer Reviews Section */}
            {/* <div className="mt-5">
              <h2 className="text-center fw-bold">What Our Clients Say</h2>
              <p className="text-center text-muted mb-4">
                Hear from our satisfied clients who have experienced our
                services and the positive impact we’ve made on their businesses.
              </p>
              <div className="row">
                <div className="col-md-2">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <p className="text-muted">
                        "Working with this startup has been a game-changer.
                        Their fresh ideas and dedication are unmatched!"
                      </p>
                      <h5 className="text-primary">- Alex Johnson</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <p className="text-muted">
                        "They bring an incredible level of energy and focus.
                        Their solutions are tailored to perfection."
                      </p>
                      <h5 className="text-primary">- Maria Gonzales</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <p className="text-muted">
                        "I admire their flexibility and innovative approach.
                        They truly understand the needs of a growing business."
                      </p>
                      <h5 className="text-primary">- Samuel Carter</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <p className="text-muted">
                        "Their attention to detail and customer service is
                        exceptional. A true partner for success."
                      </p>
                      <h5 className="text-primary">- Emma White</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <p className="text-muted">
                        "The team’s creativity and problem-solving skills are
                        beyond expectations. Highly recommended!"
                      </p>
                      <h5 className="text-primary">- Mark Lee</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <p className="text-muted">
                        "A great experience from start to finish. They’re
                        dedicated to making clients happy."
                      </p>
                      <h5 className="text-primary">- Lisa Ray</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            
            {/* Employee Spotlight Section */}
            <div className="mt-5">
              <h2 className="text-center fw-bold">Meet Our Team</h2>
              <div className="row">
                
                <div className="col-md-4">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <h5 className="text-primary">Ranjeet Kumar</h5>
                      <p className="text-muted">
                        CEO & Founder
                      </p>
                      <p className="text-muted">
                        Qualification: Master in Psychology
                      </p>{" "}
                      {/* Added qualification */}
                      
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <h5 className="text-primary">Pradeep Rawal</h5>
                      <p className="text-muted">Operation Head</p>
                      <p className="text-muted">
                        Qualification: M.Tech in Civil Engineering
                      </p>{" "}
                      {/* Added qualification */}
                      
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <h5 className="text-primary">Vishal Kumar</h5>
                      <p className="text-muted">
                        Technical Head & Transformation
                      </p>
                      <p className="text-muted">
                        Qualification: B.Tech in Computer Science
                      </p>{" "}
                      {/* Added qualification */}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Video Section */}
            {/* <div className="mt-5 text-center">
              <h2 className="fw-bold">Our Journey in Motion</h2>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/sample-video-id"
                title="Our Company Story"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div> */}
          </div>
        </section>
      </div>
    );
  }
}
