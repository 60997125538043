import React, { Component } from "react";
import technicalsolution from "../assests/technicalsolution.webp";
import housekeeping from "../assests/housekeeping.jpg";
import cattering from "../assests/cattering.jpeg";
import security from "../assests/security.webp";
// import event from "../assests/event.jpg"
import event from "../assests/event.webp";
import it from "../assests/it.jpg";

import "../styles/Services.css"; // Import the CSS file for the styles

export default class Services extends Component {
  render() {
    return (
      <div id="services">
        <section className="services-section py-5">
          <div className="container">
            {/* Section Heading */}
            <div className="text-center mb-5">
              <h1 className="fw-bold">Our Services</h1>
              <p className="text-muted">
                Discover the range of services we offer to cater to your needs.
              </p>
            </div>

            {/* Services Grid */}
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
              {/* Service Card 4 */}
              <div className="col">
                <div className="card h-100 text-center shadow-sm">
                  <img src={event} className="card-img-top" alt="Service 4" />
                  <div className="card-body">
                    <h5 className="card-title">Event Management</h5>
                    <p className="card-text">
                      Make your events unforgettable with our professional event
                      management services. From planning and coordination to
                      execution, we take care of every detail to ensure a
                      seamless and memorable experience. Perfect for weddings,
                      corporate events, and more.
                    </p>
                  </div>
                </div>
              </div>

              {/* Service Card 5 */}
              <div className="col">
                <div className="card h-100 text-center shadow-sm">
                  <img src={it} className="card-img-top" alt="Service 5" />
                  <div className="card-body">
                    <h5 className="card-title">IT Services</h5>
                    <p className="card-text">
                      Empower your business with our cutting-edge IT services.
                      From system maintenance and software development to
                      cybersecurity and cloud solutions, our experts provide
                      innovative and reliable tech support tailored to your
                      needs.
                    </p>
                  </div>
                </div>
              </div>

              {/* Service Card 6 */}
              <div className="col">
                <div className="card h-100 text-center shadow-sm">
                  <img
                    src={cattering}
                    className="card-img-top"
                    alt="Service 6"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Catering Services</h5>
                    <p className="card-text">
                      Delight your guests with our exceptional catering
                      services. From gourmet meals to customized menus, we bring
                      flavors to life for weddings, corporate events, and
                      private gatherings. Our dedicated team ensures every bite
                      is memorable and every event is seamless.
                    </p>
                  </div>
                </div>
              </div>

              {/* Service Card 1 */}
              <div className="col">
                <div className="card h-100 text-center shadow-sm">
                  <img
                    src={housekeeping}
                    className="card-img-top"
                    alt="Service 1"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Housekeeping</h5>
                    <p className="card-text">
                      Our housekeeping services ensure a spotless, organized,
                      and hygienic environment for your home or office. Whether
                      it’s daily cleaning, deep cleaning, or specialty care, we
                      use eco-friendly products and advanced techniques to
                      deliver excellence.
                    </p>
                  </div>
                </div>
              </div>

              {/* Service Card 2 */}
              <div className="col">
                <div className="card h-100 text-center shadow-sm">
                  <img
                    src={security}
                    className="card-img-top"
                    alt="Service 2"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Security Management</h5>
                    <p className="card-text">
                      Ensure the safety of your premises with our comprehensive
                      security management solutions. We provide trained
                      personnel, advanced surveillance systems, and tailored
                      strategies to safeguard your home or business around the
                      clock.
                    </p>
                  </div>
                </div>
              </div>

              {/* Service Card 3 */}
              <div className="col">
                <div className="card h-100 text-center shadow-sm">
                  <img
                    src={technicalsolution}
                    className="card-img-top"
                    alt="Service 3"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Expert Technician Services</h5>
                    <p className="card-text">
                      Our skilled technicians are here to help with all your
                      technical needs, from electrical repairs and plumbing
                      solutions to appliance maintenance. Reliable and
                      efficient, they ensure your systems are running smoothly
                      and safely.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
