import "./App.css";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Services from "./components/Services";

function IntroToServices() {
  return (
    <section className="intro-to-services py-5 bg-light">
      <div className="container">
        <h2 className="fw-bold text-center mb-4">Why Choose Our Services?</h2>
        <div className="row">
          <div className="col-md-6">
            <p className="lead">
              We are committed to providing top-notch solutions tailored to meet
              your specific needs. Our services are designed with your success
              in mind, focusing on innovation, quality, and efficiency.
            </p>
            <p>
              Our experienced team of professionals is dedicated to helping your
              business grow by delivering customized strategies that maximize
              your potential. Whether you’re a startup or an established
              organization, we work with you to create solutions that align with
              your objectives and set you apart from the competition.
            </p>
            <p>
              From initial consultation to final implementation, our hands-on
              approach ensures that every project is executed smoothly and with
              the utmost care. We believe in long-term partnerships and are here
              to support your ongoing success.
            </p>
          </div>
          <div className="col-md-6">
            <ul className="list-unstyled">
              <li>
                <strong>Expertise:</strong> Our team brings years of specialized
                experience in various industries to provide you with insights
                and strategies that work.
              </li>
              <li>
                <strong>Custom Solutions:</strong> We don’t believe in
                one-size-fits-all. We tailor our services to fit your unique
                needs and challenges.
              </li>
              <li>
                <strong>Innovation:</strong> We embrace the latest technologies
                and trends to offer you forward-thinking solutions that keep you
                ahead of the curve.
              </li>
              <li>
                <strong>Transparency:</strong> We prioritize open communication
                and full transparency to build trust and ensure alignment
                throughout the process.
              </li>
              <li>
                <strong>Customer Success:</strong> Your satisfaction is our top
                priority, and we are dedicated to ensuring that you see
                measurable results from our partnership.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

// In your App component
function App() {
  return (
    <>
      <Navbar />
      <main>
        <Home />
        <IntroToServices />
        <Services />
        <About />
        <ContactUs />
      </main>
    </>
  );
}

export default App;
