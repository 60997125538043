import React, { Component } from "react";

import Slider1 from "../assests/slide1.avif";
import Slider2 from "../assests/slide2.avif";
import Slider3 from "../assests/slider3.jpg";

import "../styles/Home.css";

export default class Home extends Component {
  render() {
    return (
      <div id="home">
        <div className="container text-center py-3">
          <h1>Welcome to Shailruchi Facility Care</h1>
          <p className="text-muted">
            Your trusted partner in facility management
          </p>
        </div>
        <div className="mt-4">{/* Carousel Component */}</div>

        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={Slider1}
                className="d-block w-100"
                alt="First slide"
                style={{ height: "500px", objectFit: "cover" }}
              />
              <div className="carousel-caption d-none d-md-block">
                <h5 style={{ color: "#D3D3D3" }}>Excellence in Service</h5>
                <p style={{ color: "#D3D3D3" }}>
                  At Shailruchi Facility Care, we strive for nothing but
                  excellence. Our expert team is dedicated to providing top-tier
                  facility management services that meet your unique needs and
                  exceed your expectations.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={Slider2}
                className="d-block w-100"
                alt="Second slide"
                style={{ height: "500px", objectFit: "cover" }}
              />
              <div className="carousel-caption d-none d-md-block">
                <h5 style={{ color: "#D3D3D3" }}>Your Comfort, Our Priority</h5>
                <p style={{ color: "#D3D3D3" }}>
                  We understand that a well-maintained facility enhances
                  productivity and well-being. Our mission is to create spaces
                  that are safe, efficient, and comfortable for everyone who
                  steps inside.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src={Slider3}
                className="d-block w-100"
                alt="Third slide"
                style={{ height: "500px", objectFit: "cover" }}
              />
              <div className="carousel-caption d-none d-md-block">
                <h5 style={{ color: "#D3D3D3" }}>Innovative Solutions</h5>
                <p style={{ color: "#D3D3D3" }}>
                  We bring the latest in technology and industry practices to
                  offer innovative solutions for facility management. Trust us
                  to streamline operations and create a more sustainable and
                  effective environment for you.
                </p>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    );
  }
}
